/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const SHOW_MENU = 'SHOW_MENU';
export const HIDE_MENU = 'HIDE_MENU';
export const HEADER_SHOW = 'HEADER_SHOW';
export const HEADER_HIDE = 'HEADER_HIDE';
export const HEADER_PAUSE = 'HEADER_PAUSE';
export const HEADER_PLAY = 'HEADER_PLAY';
export const SLIDE_CLICK = 'SLIDE_CLICK';
