import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { HIDE_MENU, SHOW_MENU } from '../lib/events';

export default el => {
    
    const $el = $(el);
    const $body = $('body');
    const $menuOpen = $el.find('[data-menu-open]');
    const $menuClose = $el.find('[data-menu-close]');
    const $menu = $el.find('[data-menu]');
    const $menuOuter = $el.find('[data-menu-outer]');
    const $menuWrap = $el.find('[data-menu-wrap]');
    const $bar = $el.find('[data-menu-bar]');
    
    let bodyWidth = $body.width();
    let isShowing = false;
    
    let { size: currentBreakpoint } = Viewport.breakpoint;
    
    const getFullWidth = () => {
        gsap.set($body.get(0), { overflow: 'hidden' });
        let resetDisplay = false;
        if ($menuOuter.get(0).style.display !== 'block') {
            gsap.set($menuOuter.get(), { display: 'block' });
            resetDisplay = true;
        }
        const withOutScrollbar = $menuOuter.width();
        gsap.set($body.get(0), { clearProps: 'overflow' });
        if (resetDisplay) {
            gsap.set($menuOuter.get(0), { clearProps: 'display' });
        }
        return withOutScrollbar;
    };
    
    const getPadderWidth = () => {
        const withScrollbar = $body.width();
        gsap.set($body.get(0), { overflow: 'hidden' });
        const withOutScrollbar = $body.width();
        gsap.set($body.get(0), { clearProps: 'overflow' });
        return withOutScrollbar - withScrollbar;
    };
    
    const resetMenu = () => {
        gsap.set($menuClose.get(), { clearProps: 'all' });
        gsap.set($menuOuter.get(), { clearProps: 'all' });
        gsap.set($menuWrap.get(), { clearProps: 'all' });
        gsap.set($body.get(0), { clearProps: 'all' });
        gsap.set($bar.get(), { clearProps: 'padding-right' });
    };
    
    const hideMenu = () => {
        if (!isShowing) {
            return;
        }
        Dispatch.emit(HIDE_MENU);
    };
    
    const showMenu = () => {
        if (isShowing) {
            return;
        }
        Dispatch.emit(SHOW_MENU);
    };
    
    const onShowMenu = () => {
        
        const width = getFullWidth();
        const padder = getPadderWidth();
        
        gsap.timeline({
                onComplete() {
                    Viewport.lockTabbing($menu.get(0));
                    gsap.set($body.get(0), { overflow: 'hidden' });
                    gsap.set($menuOuter.get(), { clearProps: 'width' });
                    gsap.set($menuWrap.get(), { clearProps: 'overflow' });
                    isShowing = true;
                }
            })
            .set($menuOuter.get(), { width, x: 400, display: 'block' })
            .set($menuClose.get(), { x: -padder })
            .set($body.get(0), { width: bodyWidth })
            .set($menuWrap.get(), { overflow: 'hidden' })
            .to($menuOuter.get(), {
                x: 0,
                duration: 0.5,
                ease: 'Quint.easeInOut'
            });
    };
    
    const onHideMenu = () => {
        const width = getFullWidth();
        
        gsap.set($menuOuter.get(), { width });
        gsap.set($menuWrap.get(), { overflow: 'hidden' });
        
        gsap.to($menuOuter.get(), {
            x: 400,
            duration: 0.5,
            ease: 'Quint.easeInOut',
            onComplete() {
                resetMenu();
                Viewport.releaseTabbing();
                Viewport.enableTabbingOnElement($menuOpen.get(0));
                isShowing = false;
            }
        });
    };
    
    const setup = () => {
        Dispatch.on(SHOW_MENU, onShowMenu);
        Dispatch.on(HIDE_MENU, onHideMenu);
        Viewport.releaseTabbing();
        Viewport.enableTabbingOnElement($menuOpen.get(0));
        $menuOpen.on('click', e => {
            e.preventDefault();
            showMenu();
        });
        $menuClose.on('click', e => {
            e.preventDefault();
            hideMenu();
        });
    };
    
    const teardown = () => {
        Viewport.releaseTabbing();
        Viewport.enableTabbingOnElement($menu.get(0));
        Dispatch.off(SHOW_MENU, onShowMenu);
        Dispatch.off(HIDE_MENU, onHideMenu);
        Dispatch.emit(HIDE_MENU);
        gsap.killTweensOf($menuOuter.get());
        resetMenu();
        $menuOpen.off('click');
        $menuClose.off('click');
        isShowing = false;
    };
    
    const onBreakpoint = () => {
        const oldBreakpoint = currentBreakpoint;
        currentBreakpoint = Viewport.breakpoint.size;
        if (oldBreakpoint < 980 && currentBreakpoint >= 980) {
            teardown();
        } else if (oldBreakpoint >= 980 && currentBreakpoint < 980) {
            setup();
        }
    };
    
    const onResize = () => {
        bodyWidth = $body.width();
        if (isShowing) {
            gsap.set($body.get(0), { clearProps: 'all' });
            gsap.set($body.get(0), { width: bodyWidth, overflow: 'hidden' });
        }
    };
    
    const onKeyUp = e => {
        if (!isShowing) {
            return;
        }
        if (e.keyCode === 27) {
            hideMenu();
        }
    };
    
    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        
        onResize();
        
        if (currentBreakpoint < 980) {
            setup();
        } else {
            Viewport.enableTabbingOnElement($menu.get(0));
        }
        
        $(window).on('keyup', onKeyUp);
        
        window.addEventListener('keyup', onKeyUp);
    };
    
    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        window.removeEventListener('keyup', onKeyUp);
        teardown();
    };
    
    return {
        init,
        destroy
    };
    
};
