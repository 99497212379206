import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import superagent from '../core/request';

export default el => {

    const $el = $(el);
    const $form = $el.find('[data-form]');
    const $checkbox = $el.find('[data-form-checkbox]');
    const $checkboxHidden = $el.find('[data-form-checkbox-hidden]');
    const $email = $el.find('[data-form-email]');
    const $emailHidden = $el.find('[data-form-email-hidden]');
    const $name = $el.find('[data-form-name]');
    const $nameHidden = $el.find('[data-form-name-hidden]');

    let request;

    const removeErrorOnFocus = $node => {
        $node.on('focus', () => {
            $node.removeClass('has-error');
            $node.off('focus');
        });
    }

    const setValues = () => {
        if (!$checkbox.get(0).checked) {
            $checkboxHidden.val('Nei');
        } else {
            $checkboxHidden.remove();
        }
        $emailHidden.val($email.val());
        $nameHidden.val($name.val());
    };

    const submitForm = () => {

        if (request) {
            return;
        }

        const data = serialize($form.get(0));

        $('.project__form-error').remove();

        request = superagent
          .post('/')
          .accept('application/json')
          .send(data);

        request
          .then(() => {
              $el.append('<span class="project__form-success" data-form-success>Takk for din henvendelse</span>');
              const form = $form.get(0);
              const success = $el.find('[data-form-success]').get(0);
              gsap.timeline()
                .to(form, { opacity: 0, duration: 0.3 })
                .fromTo(success, { opacity: 0 }, { opacity: 1, duration: 0.3 });
          })
          .catch(error => {
              const { response } = error;
              const errors = response.body.errors || {};
              const formUid = $form.data('uid');
              Object.keys(errors).forEach(key => {
                  const $node = $form.find(`#${key.replace('.', '-')}-${formUid}`).eq(0);
                  $node.addClass('has-error');
                  $form.append(`
                    <span id="form-error-${key}" class="project__form-error">${errors[key].join('<br/>')}</span>
                  `);
                  removeErrorOnFocus($node);
              });
          })
          .then(() => {
              request = null;
          });

    };

    $form.on('submit', e => {
        e.preventDefault();
        setValues();
        submitForm();
    });

    return {
        destroy() {
            $form.off('submit');
            if (request) {
                request.abort();
                request = null;
            }
        }
    };

};
