import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import $ from './core/Dom';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    Components.init();

    $('[data-scroll-to-top]').on('click', e => {
        e.preventDefault();
        Viewport.scrollTo(0);
    });

};

require('doc-ready')(init);
