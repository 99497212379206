import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import {
    HEADER_PAUSE, HEADER_PLAY, HIDE_MENU, SHOW_MENU
} from '../lib/events';

gsap.registerPlugin(ScrollToPlugin);

export default el => {
    
    const $el = $(el);
    
    const RECENT_TRIGGER_OFFSET = {
        440: 0,
        600: 0,
        750: 50,
        980: 68,
        1200: 83,
        1420: 115,
        1800: 112
    };
    
    const $menuInner = $el.find('[data-project-menu-inner]');
    const $titleWrap = $el.find('[data-project-menu-titles]');
    const $titleInner = $el.find('[data-project-menu-titles-inner]');
    const $link = $el.find('[data-project-menu-link]');
    const $mobileMenu = $('[data-project-menu-mobile]');
    const $linkMobile = $mobileMenu.find('[data-project-menu-link-mobile]');
    const $recent = $('[data-project-recent]');
    const sections = $('[data-project-section]').get();
    
    let { size: currentBreakpoint } = Viewport.breakpoint;
    
    let viewportHeight = 0;
    let activeSection;
    let isAnimating = false;
    let scrollTop = 0;
    let isMobile = currentBreakpoint < 750;
    let sectionData = [];
    let recentOffset;
    let recentOffsetAmount;
    let menuHeight;
    let isFixed = true;
    
    const createSectionData = () => {
        sectionData = sections.map((section, index) => ({
            section: $(section),
            offset: $(section).offset().top,
            distance: 0,
            index: index + 1
        }));
    };
    
    const moveMenu = index => {
        const rollAmount = parseInt(index, 10) * 100;
        gsap.to($menuInner.get(), { yPercent: -rollAmount, duration: 0.3 });
    };
    
    const toggleActiveItem = index => {
        $el.find('[data-project-menu-link].-is-active').removeClass('-is-active');
        $el.find(`[data-project-menu-link][data-index="${index}"]`).addClass('-is-active');
    };
    
    const rollTitle = index => {
        const rollAmount = parseInt(index, 10) * 100;
        $el.find('[data-project-menu-title].-is-active').removeClass('-is-active');
        $el.find(`[data-project-menu-title="${index}"]`).addClass('-is-active');
        gsap.to($titleInner.get(), { yPercent: -rollAmount, duration: 0.3 });
        gsap.to($titleWrap.get(), { yPercent: rollAmount, duration: 0.3 });
    };
    
    const updateMenu = index => {
        rollTitle(index);
        toggleActiveItem(index);
        moveMenu(index);
    };
    
    const updateSectionData = (scrollPos, shouldUpdateMenu) => {
        
        if (isMobile) {
            sectionData.forEach(item => {
                item.distance = (viewportHeight / 3) + scrollPos - item.offset;
            });
        } else {
            sectionData.forEach(item => {
                item.distance = (viewportHeight / 2) + scrollPos - item.offset;
            });
        }
        
        const currentSection = sectionData.filter(item => item.distance >= 0).sort((a, b) => {
            if (a.distance < b.distance) {
                return -1;
            }
            if (a.distance > b.distance) {
                return 1;
            }
            return 0;
        })[0];
        
        if (currentSection !== activeSection) {
            activeSection = currentSection;
            if (shouldUpdateMenu) {
                updateMenu(typeof activeSection !== 'undefined' ? activeSection.index : 0);
            }
        }
    };
    
    const scrollTo = id => {
        isAnimating = true;
        
        let offsetAmount;
        
        if (isMobile) {
            Dispatch.emit(HEADER_PAUSE);
            offsetAmount = $(`#${id}`).offset().top - 50;
        } else {
            offsetAmount = $(`#${id}`).offset().top > (viewportHeight / 2) ? $(`#${id}`).offset().top - (viewportHeight / 2) + 40 : $(`#${id}`).offset().top;
        }
        
        gsap.to(window, { scrollTo: { y: offsetAmount }, ease: 'Quart.easeOut', duration: 1 });
        
        gsap.delayedCall(1, () => {
            isAnimating = false;
            updateSectionData(scrollTop, false);
            if (isMobile) {
                Dispatch.emit(HEADER_PLAY);
            }
        });
    };
    
    const onShowMenu = () => {
        
        updateSectionData(Viewport.scrollTop, false);
        
        const index = typeof activeSection !== 'undefined' ? activeSection.index : 0;
        
        $mobileMenu.find('.-is-active').removeClass('-is-active');
        $mobileMenu.find(`[data-index="${index}"]`).addClass('-is-active');
    };
    
    const toggleFixedState = fix => {
        if (fix) {
            gsap.set(el, { clearProps: 'all' });
            isFixed = true;
        } else {
            gsap.set(el, { position: 'absolute', top: recentOffsetAmount });
            isFixed = false;
        }
    };
    
    const checkRecentPosition = scrolling => {
        if (scrolling) {
            if (isFixed && (scrollTop + (viewportHeight / 2)) >= recentOffsetAmount) {
                toggleFixedState(false);
            } else if (!isFixed && (scrollTop + (viewportHeight / 2)) < recentOffsetAmount) {
                toggleFixedState(true);
            }
        } else if ((scrollTop + (viewportHeight / 2)) >= recentOffsetAmount) {
            toggleFixedState(false);
        } else if ((scrollTop + (viewportHeight / 2)) < recentOffsetAmount) {
            toggleFixedState(true);
        }
    };
    
    const setupMobile = () => {
        
        Dispatch.on(SHOW_MENU, onShowMenu);
        
        $linkMobile.on('click', function (e) {
            e.preventDefault();
            Dispatch.emit(HIDE_MENU);
            scrollTo($(this).data('project-menu-target'));
        });
    };
    
    const onScroll = () => {
        
        scrollTop = Viewport.scrollTop;
        
        if (!isAnimating) {
            updateSectionData(scrollTop, true);
        }
        
        checkRecentPosition(true);
        
    };
    
    const setup = () => {
        Viewport.on('scroll', onScroll);
        $link.on('click', function (e) {
            e.preventDefault();
            scrollTo($(this).data('project-menu-target'));
            updateMenu($(this).data('index'));
        });
    };
    
    const teardown = () => {
        Viewport.off('scroll', onScroll);
        $link.off('click');
    };
    
    const teardownMobile = () => {
        Dispatch.off(SHOW_MENU, onShowMenu);
        $linkMobile.off('click');
    };
    
    const onBreakpoint = () => {
        
        const oldBreakpoint = currentBreakpoint;
        currentBreakpoint = Viewport.breakpoint.size;
        
        isMobile = currentBreakpoint < 750;
        
        if (oldBreakpoint < 750 && currentBreakpoint >= 750) {
            setup();
            teardownMobile();
        } else if (oldBreakpoint >= 750 && currentBreakpoint < 750) {
            teardown();
            setupMobile();
        }
        
    };
    
    const onResize = () => {
        
        scrollTop = Viewport.scrollTop;
        viewportHeight = Viewport.height;
        recentOffset = $recent.offset().top;
        menuHeight = $el.height();
        recentOffsetAmount = recentOffset - ((menuHeight / 2) + RECENT_TRIGGER_OFFSET[currentBreakpoint]);
        checkRecentPosition(false);
        sectionData.forEach(item => {
            item.offset = item.section.offset().top;
        });
    };
    
    const init = () => {
        
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        
        createSectionData();
        
        if (currentBreakpoint >= 750) {
            setup();
        } else {
            setupMobile();
        }
        
        $('[data-internal-button]').on('click', function (e) {
            e.preventDefault();
            const target = $(this).data('internal-button');
            scrollTo(target);
            updateMenu($(`[data-project-menu-link][data-project-menu-target="${target}"]`).data('index'));
        });
        
        onResize();
        
    };
    
    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        $('[data-internal-button]').off('click');
        
        if (isMobile) {
            teardownMobile();
        } else {
            teardown();
        }
    };
    
    return {
        init,
        destroy
    };
    
};
