import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { HEADER_PAUSE, HEADER_PLAY, HIDE_MENU, SHOW_MENU } from '../lib/events';

export default el => {

    const OFFSET_TOP_DOWN = 137;
    const OFFSET_TOP_UP = 34;
    const OFFSET_BUFFER = 10;
    const MENU_PADDING_BOTTOM = 34;

    const $el = $(el);
    const $body = $('body');
    const $logoText = $el.find('[data-logo-text]');
    const isDark = $body.hasClass('dark-header');

    let lastTop = 0;
    let isLarge;
    let isSmall = false;
    let isActive = false;
    let menuPaddingTop;
    let $bar;

    let { size: currentBreakpoint } = Viewport.breakpoint;

    const pause = () => {
        if (!isActive) {
            return;
        }
        Dispatch.emit(HEADER_PAUSE);
    };

    const play = () => {
        if (isActive) {
            return;
        }
        Dispatch.emit(HEADER_PLAY);
    };

    const setPadding = () => {
        if (currentBreakpoint >= 980 && currentBreakpoint < 1420) {
            menuPaddingTop = 46;
        } else if (currentBreakpoint >= 1420) {
            menuPaddingTop = 69;
        }
    };

    const resetState = () => {

        setPadding();

        if (isSmall && currentBreakpoint >= 980) {
            gsap.set($logoText.get(), { opacity: 0 });
            gsap.set($bar.get(), { paddingTop: 10, paddingBottom: 10, overflow: 'hidden' });
        }

    };

    const toggleTransparency = toDark => {
        if (isDark) {
            if (toDark) {
                gsap.set($bar.get(), { background: 'rgba(0,0,0,0.6)' });
            } else if (currentBreakpoint >= 980) {
                gsap.to($bar.get(), { background: 'rgba(0,0,0,0)', duration: 0.3 });
            } else {
                gsap.to($bar.get(), { background: 'rgba(0,0,0,1)', duration: 0.3 });
            }
        }
    };

    const toggleSize = small => {
        if (small && isSmall) {
            if (currentBreakpoint >= 980) {
                gsap.timeline()
                    .set($bar.get(), { clearProps: 'overflow' })
                    .to($bar.get(), { paddingBottom: MENU_PADDING_BOTTOM, paddingTop: menuPaddingTop, duration: 0.3, ease: 'Sine.easeOut' })
                    .to($logoText.get(), { opacity: 1, duration: 0.5, ease: 'Quad.easeInOut' });
            }
            toggleTransparency(!small);
            isSmall = false;
        } else if (!small && !isSmall) {
            if (currentBreakpoint >= 980) {
                gsap.timeline()
                    .to($bar.get(), { paddingTop: 10, paddingBottom: 10, overflow: 'hidden', duration: 0.3 }, 0)
                    .to($logoText.get(), { opacity: 0, duration: 0.1 }, 0);
            }
            toggleTransparency(!small);
            isSmall = true;
        }
    }

    const onHeaderPause = () => {
        isActive = false;
    };

    const onHeaderPlay = () => {
        isActive = true;
        lastTop = Viewport.scrollTop;
    };

    const onBreakpoint = () => {
        const oldBreakpoint = currentBreakpoint;
        currentBreakpoint = Viewport.breakpoint.size;
        gsap.killTweensOf($bar.get());
        gsap.set($bar.get(), { clearProps: 'all' });
        if (oldBreakpoint < 980 && currentBreakpoint >= 980) {
            $bar = $el.find('[data-menu-bar-wrap]');
            isLarge = true;
        } else if (oldBreakpoint >= 980 && currentBreakpoint < 980) {
            $bar = $el.find('[data-menu-bar]');
            isLarge = false;
        }
        resetState();
    };

    const onScroll = () => {
        if (!isActive) {
            return;
        }

        const { scrollTop } = Viewport;

        if (Math.abs(scrollTop - lastTop) > OFFSET_BUFFER) {

            const isScrollingUp = scrollTop < lastTop;

            if (isScrollingUp) {
                toggleSize(scrollTop < OFFSET_TOP_DOWN);
            } else if (scrollTop > OFFSET_TOP_UP) {
                toggleSize(false);
            }

            lastTop = scrollTop;
        }
    };

    const init = () => {

        if (currentBreakpoint < 980) {
            $bar = $el.find('[data-menu-bar]');
            isLarge = false;
        } else {
            $bar = $el.find('[data-menu-bar-wrap]');
            isLarge = true;
        }

        setPadding();

        Dispatch.on(SHOW_MENU, pause);
        Dispatch.on(HIDE_MENU, play);
        Dispatch.on(HEADER_PAUSE, onHeaderPause);
        Dispatch.on(HEADER_PLAY, onHeaderPlay);

        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('scroll', onScroll);

        play();

    };

    const destroy = () => {
        Dispatch.off(SHOW_MENU, pause);
        Dispatch.off(HIDE_MENU, play);
        Dispatch.off(HEADER_PAUSE, onHeaderPause);
        Dispatch.off(HEADER_PLAY, onHeaderPlay);

        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };

};
