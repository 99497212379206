import Flickity from 'flickity';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { SLIDE_CLICK } from '../lib/events';

export default el => {
    
    const $el = $(el);
    const $slideContainer = $el.find('[data-slide-container]');
    
    let flkty;
    
    const init = () => {
        flkty = new Flickity($slideContainer.get(0), {
            cellSelector: '[data-slide]',
            initialIndex: 0,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
            dragThreshold: 10,
            percentPosition: false,
            setGallerySize: false
        });
        
        if ($el.data('has-popup') !== 'undefined') {
            flkty.on('staticClick', (event, pointer, cellElement) => {
                Dispatch.emit(SLIDE_CLICK, cellElement);
            });
        }
    };
    
    const destroy = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    };
    
    return {
        init,
        destroy
    };
    
};
