import Flickity from 'flickity';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { SLIDE_CLICK } from '../lib/events';

export default el => {
    
    const $el = $(el);
    const $slideContainer = $el.find('[data-slide-container]');
    const $slide = $el.find('[data-slide]');
    
    let flkty;
    
    let { size: currentBreakpoint } = Viewport;
    
    const setup = () => {
        
        $el.addClass('-has-slideshow');
        
        flkty = new Flickity($slideContainer.get(0), {
            cellSelector: '[data-slide]',
            initialIndex: 1,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: false,
            dragThreshold: 10,
            percentPosition: false
        });
        
        if ($el.data('has-popup') !== 'undefined') {
            flkty.on('staticClick', (event, pointer, cellElement) => {
                Dispatch.emit(SLIDE_CLICK, cellElement);
            });
        }
    };
    
    const teardown = () => {
        $el.removeClass('-has-slideshow');
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    }
    
    const onBreakpoint = () => {
        const oldBreakpoint = currentBreakpoint;
        currentBreakpoint = Viewport.breakpoint.size;
        if (oldBreakpoint < 600 && currentBreakpoint >= 600) {
            teardown();
        } else if (oldBreakpoint >= 600 && currentBreakpoint < 600) {
            setup();
        }
    };
    
    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        if (currentBreakpoint < 600) {
            setup();
        }
    };
    
    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        teardown();
    };
    
    return {
        init,
        destroy
    };
    
};
