import $ from '../core/Dom';
import Config from '../core/Config';
import Viewport from '../core/Viewport';

export default (el, { locations = [] }) => {
    
    const $el = $(el);
    
    const { googleMapsApiKey } = Config.get();
    
    const $mapContainer = $el.find('[data-map]');
    const hasMultiplePoints = locations.length > 1;
    const zoom = $mapContainer.data('zoom') ? parseInt($mapContainer.data('zoom'), 10) : 13;
    
    let map;
    let bounds;
    
    const getMapStyles = () => [
        { elementType: 'geometry', stylers: [{ color: '#252525' }] },
        { elementType: 'labels.text.stroke', stylers: [{ color: '#252525' }] },
        { elementType: 'labels.text.fill', stylers: [{ color: '#787878' }] },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#383838' }]
        },
        {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#484848' }]
        },
        {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'on' }, { saturation: '-100' }, { lightness: '-20' }]
        },
        {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#2d333d' }]
        }
    ];
    
    const calculateBounds = () => {
        
        const north = locations.sort((a, b) => {
            if (parseFloat(a.lat) > parseFloat(b.lat)) {
                return -1;
            }
            if (parseFloat(a.lat) < parseFloat(b.lat)) {
                return 1;
            }
            return 0;
        })[0].lat;
        
        const south = locations.sort((a, b) => {
            if (parseFloat(a.lat) < parseFloat(b.lat)) {
                return -1;
            }
            if (parseFloat(a.lat) > parseFloat(b.lat)) {
                return 1;
            }
            return 0;
        })[0].lat;
        
        const east = locations.sort((a, b) => {
            if (parseFloat(a.lng) > parseFloat(b.lng)) {
                return -1;
            }
            if (parseFloat(a.lng) < parseFloat(b.lng)) {
                return 1;
            }
            return 0;
        })[0].lng;
        
        const west = locations.sort((a, b) => {
            if (parseFloat(a.lng) < parseFloat(b.lng)) {
                return -1;
            }
            if (parseFloat(a.lng) > parseFloat(b.lng)) {
                return 1;
            }
            return 0;
        })[0].lng;
        
        bounds = new window.google.maps.LatLngBounds({ lat: parseFloat(south), lng: parseFloat(west) }, { lat: parseFloat(north), lng: parseFloat(east) });
        
    };
    
    const fitMapToBounds = () => {
        if (!map) {
            return;
        }
        map.fitBounds(bounds);
    };
    
    function CustomMarker(latlng, mapRef, args) {
        this.latlng = latlng;
        this.args = args;
        this.setMap(mapRef);
    }
    
    const addCustomMarker = () => {
        CustomMarker.prototype = new window.google.maps.OverlayView();
        
        // eslint-disable-next-line func-names
        CustomMarker.prototype.draw = function () {
            
            const self = this;
            
            let { div } = this;
            
            if (!div) {
                
                div = this.div = document.createElement('div');
                if (hasMultiplePoints) {
                    div.innerHTML = `<a href="${this.args.link}">${this.args.name}</a>`;
                } else {
                    div.innerHTML = this.args.name;
                }
                
                div.className = 'map__marker';
                div.style.position = 'absolute';
                div.style.cursor = 'pointer';
                
                if (typeof (self.args.marker_id) !== 'undefined') {
                    div.dataset.marker_id = self.args.marker_id;
                }
                
                div.addEventListener('click', () => {
                    window.google.maps.event.trigger(self, 'click');
                });
                
                const panes = this.getPanes();
                panes.overlayImage.appendChild(div);
            }
            
            const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
            
            if (point) {
                div.style.left = `${point.x}px`;
                div.style.top = `${(point.y - 45)}px`;
            }
        };
        
        CustomMarker.prototype.remove = function () {
            if (this.div) {
                this.div.parentNode.removeChild(this.div);
                this.div = null;
            }
        };
        
        CustomMarker.prototype.getPosition = function () {
            return this.latlng;
        };
    };
    
    const initMap = () => {
        
        if (!window.google.maps) {
            console.error('Google Maps API not loaded');
            return;
        }
        
        const customMapTypeId = 'custom_style';
        const customMapType = new window.google.maps.StyledMapType(getMapStyles(), {
            name: 'Custom Style'
        });
        
        map = new window.google.maps.Map($mapContainer.get(0), {
            center: { lat: parseFloat(locations[0].lat), lng: parseFloat(locations[0].lng) },
            mapTypeControl: false,
            streetViewControl: false,
            scrollwheel: false,
            draggable: true,
            zoom
        });
        
        if (locations.length > 1) {
            calculateBounds();
            fitMapToBounds();
        }
        
        map.mapTypes.set(customMapTypeId, customMapType);
        map.setMapTypeId(customMapTypeId);
        
        addCustomMarker();
        
        for (let i = 0, len = locations.length; i < len; i += 1) {
            // eslint-disable-next-line no-new
            new CustomMarker(
                new window.google.maps.LatLng(parseFloat(locations[i].lat), parseFloat(locations[i].lng)),
                map,
                {
                    name: locations[i].name,
                    link: locations[i].link
                }
            );
        }
    };
    
    const loadGmaps = () => {
        const gmapsUrl = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&callback=Function.prototype`;
        if (!$('body').find(`script[src="${gmapsUrl}"]`).get(0)) { // Make sure we only load gmaps once
            const gmaps = document.createElement('script');
            gmaps.src = gmapsUrl;
            document.body.appendChild(gmaps);
        }
        // Poll for google.maps
        const then = new Date().getTime();
        const pollForGmaps = () => {
            if (window.google && window.google.maps) {
                initMap();
            } else if ((new Date().getTime()) - then < 5000) {
                requestAnimationFrame(pollForGmaps);
            } else {
                console.error('Failed to load Google Maps API');
            }
        };
        pollForGmaps();
    };
    
    let observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (!isIntersecting) {
            return;
        }
        observer.disconnect();
        observer = null;
        loadGmaps();
    });
    
    observer.observe(el);
    
    const onResize = () => {
        if (!map) {
            return;
        }
        if (hasMultiplePoints) {
            fitMapToBounds();
        } else {
            map.setCenter({ lat: parseFloat(locations[0].lat), lng: parseFloat(locations[0].lng) });
        }
    };
    
    Viewport.on('resize', onResize);
    
    return {
        destroy() {
            if (observer) {
                observer.disconnect();
                observer = null;
            }
            Viewport.off('resize', onResize);
        }
    };
    
};
