import gsap from 'gsap';
import Flickity from 'flickity';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import superagent from '../core/request';
import { SLIDE_CLICK } from '../lib/events';

export default el => {
    
    const $el = $(el);
    const $images = $el.find('[data-has-zoom]');
    
    const { url } = el.dataset;
    
    let hasModal = false;
    let isShowing = false;
    let triggerIndex = 0;
    let scrollLocked = false;
    
    let $modal;
    let flkty;
    
    let { scrollTop: scrollTopBeforeLock } = Viewport;
    
    const lockScroll = () => {
        
        if (scrollLocked) {
            return;
        }
        
        const $body = $('body');
        
        scrollLocked = true;
        scrollTopBeforeLock = Viewport.scrollTop;
        
        $body
            .css({
                overflow: 'hidden',
                position: 'absolute',
                width: '100%',
                height: `calc(100vh + ${scrollTopBeforeLock}px)`,
                top: `-${scrollTopBeforeLock}px`,
                left: 0
            });
        
        $('html')
            .css({
                position: 'absolute',
                width: '100%',
                height: `calc(100vh + ${scrollTopBeforeLock}px)`,
                left: 0,
                top: 0,
                overflow: 'hidden'
            });
        
        window.scrollTo(0, 0);
    };
    
    const releaseScroll = () => {
        
        if (!scrollLocked) {
            return;
        }
        
        scrollLocked = false;
        
        $('body')
            .css({
                overflow: '',
                position: '',
                width: '',
                height: '',
                top: '',
                left: ''
            });
        
        $('html')
            .css({
                position: '',
                width: '',
                height: '',
                overflow: '',
                left: '',
                top: '',
                borderRight: ''
            });
        
        window.scrollTo(0, scrollTopBeforeLock);
    };
    
    function showModal() {
        
        if (isShowing) {
            return;
        }
        
        isShowing = true;
        
        $modal
            .on('dragstart', '*', e => {
                e.preventDefault();
            })
            .on('touchmove', e => {
                e.preventDefault();
            });
        
        flkty.selectCell(triggerIndex, false, true);
        
        gsap.set($modal.get(), { opacity: 0, display: 'block' });
        gsap.set($modal.find('[data-slide]').get(), { opacity: 0 });
        gsap.to($modal.get(), {
            duration: 0.5,
            opacity: 1,
            onComplete() {
                lockScroll(false);
                flkty.resize();
                gsap.to($modal.find('[data-slide]').get(), { opacity: 1, duration: 0.25 });
            }
        });
    }
    
    const hideModal = () => {
        if (!isShowing) {
            return;
        }
        isShowing = false;
        releaseScroll();
        $modal.off('dragstart touchmove');
        gsap.to($modal.get(), { opacity: 0, clearProps: 'all', duration: 0.5 });
    };
    
    const initSlider = () => {
        flkty = new Flickity($modal.find('[data-slider]').get(0), {
            cellSelector: '[data-slide]',
            initialIndex: triggerIndex,
            wrapAround: false,
            prevNextButtons: true,
            pageDots: false,
            dragThreshold: 10,
            percentPosition: true,
            setGallerySize: false
        });
        $modal.find('.lazyload').addClass('lazypreload');
    }
    
    const fetchModal = () => {
        console.log('fetch modal');
        superagent
            .get(`${url}?view=slides`)
            .then(({ status, text }) => {
                if (status === 200 && text) {
                    hasModal = true;
                    $modal = $(text);
                    $el.append($modal);
                    gsap.set($modal.get(0), { opacity: 0, display: 'block' });
                    initSlider();
                    showModal();
                } else {
                    throw new Error();
                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    
    const onClick = item => {
        if (!item) {
            return;
        }
        triggerIndex = $images.get().indexOf(item);
        if (!hasModal) {
            fetchModal();
        } else {
            showModal();
        }
    };
    
    const onSlideClick = (event, slide) => {
        onClick($(slide).find('[data-has-zoom]').get(0));
    };
    
    const onCloseClick = e => {
        e.preventDefault();
        hideModal();
    };
    
    const onKeyUp = e => {
        if (!isShowing) {
            return;
        }
        if (e.keyCode === 27) {
            hideModal();
        }
    };
    
    const init = () => {
        Dispatch.on(SLIDE_CLICK, onSlideClick);
        $el.on('click', '[data-has-zoom="image"]', e => {
            e.preventDefault();
            onClick(e.triggerTarget);
        });
        $el.on('click', '[data-modal-close]', onCloseClick);
        $(window).on('keyup', onKeyUp);
    };
    
    const destroy = () => {
        Dispatch.off(SLIDE_CLICK, onSlideClick);
        $el.off('click');
        $(window).off('keyup', onKeyUp);
        hideModal();
        if (flkty) {
            flkty.destroy();
        }
    };
    
    return {
        init,
        destroy
    };
    
};
